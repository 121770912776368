<script setup lang="ts">
import type { Modello } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { link } from "~/lib/utils/contents";

defineProps<{
  content: Modello;
  compact?: boolean;
  showAreas?: boolean;
  showChildren?: boolean;
  showParents?: boolean;
}>();

const df = new DateFormatter("it-IT", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});
</script>

<template>
  <FrontContentItemsBaseContent v-bind="$props">
    <template #center>
      <div class="mt-3" v-if="content.last_update && content.is_updated">
        <span class="chip chip-green">Aggiornato il {{ df.format(content.last_update) }}</span>
      </div>
    </template>
    <template #info>
      <p class="info-number mt-2 md:mt-0">Cod. {{ content.code }}</p>
    </template>
    <template #footer>
      <FrontModelDownloadButton :content="content" />
      <div class="text-base mt-1" v-if="content.pratiche?.length && showParents">
        <span class="text-primary font-semibold">Contenuto in:</span>
        <ul class="list-disc list-inside ps-4">
          <li v-for="pratica in content.pratiche">
            <NuxtLink class="hover:text-primary-600 transition-colors" :to="link({ content: pratica })">{{ pratica.title }}</NuxtLink>
          </li>
        </ul>
      </div>
    </template>
  </FrontContentItemsBaseContent>
</template>
