<script setup lang="ts">
import { type Memoweb } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: Memoweb;
  compact?: boolean;
  showAreas?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", { dateStyle: "long" });
</script>

<template>
  <FrontContentItemsBaseItem
    v-bind="$props"
    :title="content.title"
    :date="content.date || content.published_at || content.created_at"
    :abstract="content.abstract"
    :show-areas="showAreas"
    :compact="compact"
    :link="link({ ...linkConfig, memoweb: content })"
    info-label="Memoweb"
  >
    <template #preview>
      <div class="font-monospace flex flex-col items-start justify-center text-base pe-4 border-e-2 border-primary mb-2 md:mb-0" v-if="content.number > 0">
        <span class="text-2xl text-primary font-semibold">{{ content.number }}</span>
        <template v-if="content.date">
          <span>{{ content.date.getFullYear() }}</span>
        </template>
      </div>
    </template>
    <template #info>
      <p class="info-number my-2">Memoweb del {{ df.format(content.date) }}</p>
    </template>
  </FrontContentItemsBaseItem>
</template>
