<script setup lang="ts">
import { type UserQuestion } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: UserQuestion;
  compact?: boolean;
  showAreas?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", { dateStyle: "long" });
</script>

<template>
  <FrontContentItemsBaseItem
    v-bind="$props"
    :title="content.title"
    :date="content.created_at"
    :abstract="content.question"
    :show-areas="showAreas"
    :compact="compact"
    :link="link({ ...linkConfig, userQuestion: content })"
    :areas="content.area ? [content.area] : []"
    info-label="Quesito Inviato"
  >
    <template #info>
      <span class="chip bg-green-600 border-green-600 text-green-50 font-semibold inline-block mt-2" v-if="content.is_completed">Parere completato</span>
      <span class="chip bg-yellow-500 border-yellow-500 text-yellow-900 font-semibold inline-block mt-2" v-else>In attesa di risposta</span>
    </template>
    <template #header>
      <p class="info-number my-2">Quesito inoltrato il {{ df.format(content.created_at) }}</p>
    </template>
  </FrontContentItemsBaseItem>
</template>
