<script setup lang="ts">
import { NEWS_TYPE_ENUM, type Notizia } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";

defineProps<{
  content: Notizia;
  compact?: boolean;
  showAreas?: boolean;
}>();

const df = new DateFormatter("it-IT", { dateStyle: "long" });
</script>

<template>
  <FrontContentItemsBaseContent v-bind="$props" :info-label="NEWS_TYPE_ENUM[content.news_type]">
    <template #info>
      <p class="info-number mt-2 md:mt-0">{{ NEWS_TYPE_ENUM[content.news_type] }} del {{ df.format(content.published_at || content.created_at) }}</p>
    </template>
    <template #preview>
      <img
        loading="lazy"
        v-if="(content.news_type === 'videonota' && content.video_thumb_url) || content.image?.original_url"
        :src="content.image?.original_url || content.video_thumb_url || undefined"
        :srcset="content.image?.srcset || undefined"
        class="mx-auto object-cover max-h-32 mb-2 md:mb-0"
        :alt="content.title"
      />
      <IconsMemoweb v-else class="flex-none text-primary w-12 h-12 mb-2"></IconsMemoweb>
    </template>
  </FrontContentItemsBaseContent>
</template>
