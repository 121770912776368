<script setup lang="ts">
import { type Scadenza } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { AREAS_MAP } from "~/lib/const";
import { link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: Scadenza;
  compact?: boolean;
  showAreas?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", { day: "numeric", month: "long" });
</script>

<template>
  <div class="flex flex-col">
    <h3 v-if="content.date" class="font-mono text-tertiary-400 mb-3">
      <span class="block font-bold text-3xl">{{ df.format(content.date) }}</span>
      <span class="text-xl">{{ content.date.getFullYear() }}</span>
    </h3>
    <NuxtLink :to="link({ ...linkConfig, content })" :title="content.title" class="font-semibold text-xl hover:text-primary-100 transition-colors mb-2">{{ content.title }}</NuxtLink>
    <p v-if="!compact" class="line-clamp-6">{{ content.abstract }}</p>
    <div v-if="!compact && showAreas" class="flex flex-wrap gap-x-2 font-semibold text-base pt-4 mt-auto">
      <span v-for="area in content.areas" :class="['font-bold text-sm', 'text-' + AREAS_MAP[area.id].color + '-foreground']">{{ area.title }}</span>
    </div>
  </div>
</template>
