<script setup lang="ts">
import type { Modello } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: Modello;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});
</script>

<template>
  <div class="pt-3 first:pt-0">
    <div class="md:flex flex-row-reverse justify-between gap-2">
      <div class="flex-none">
        <p class="info-number md:text-right">Cod. {{ content.code }}</p>
      </div>
      <NuxtLink :to="link({ ...linkConfig, content })" class="font-medium leading-tight hover:text-primary-600 transition-colors">{{ content.title }}</NuxtLink>
    </div>
    <div class="text-right" v-if="content.last_update && content.is_updated">
      <span class="chip chip-green inline-block mt-2">Aggiornato il {{ df.format(content.last_update) }}</span>
    </div>
    <div class="text-end mt-2">
      <FrontModelDownloadButton :content="content" />
    </div>
  </div>
</template>
