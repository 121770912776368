<script setup lang="ts">
import type { Modello } from "@gasparigit/omnia-sdk";

const props = defineProps<{
  content: Modello;
}>();

const hasAttachments = computed(() => {
  if (!props.content.files?.length) return false;

  for (const file of props.content.files) {
    if (file.file) return true;
  }

  return false;
});

const singleFile = computed(() => {
  if (!hasAttachments.value) return null;

  if (props.content.files?.length === 1) return props.content.files[0];

  return null;
});

const canDownloadImmediately = computed(() => {
  if (!singleFile.value || singleFile.value.is_autofillable) return false;

  return true;
});
</script>

<template>
  <template v-if="hasAttachments">
    <FrontDownloadButton class="ms-auto w-auto" v-if="canDownloadImmediately && singleFile" :title="`${singleFile.title} - ${content.title}`" :content="content" :file="singleFile">
      <span title="Scarica documento" class="link text-base ms-auto py-0 h-auto"><Icon name="carbon:download" class="me-1 h-4 w-4" />Scarica</span>
    </FrontDownloadButton>
    <Dialog v-else>
      <DialogTrigger as-child>
        <Button variant="link" title="Scarica documento" class="text-base ms-auto py-0 h-auto"><Icon name="carbon:download" class="me-1 h-4 w-4" />Scarica</Button>
      </DialogTrigger>
      <DialogScrollContent class="max-w-3xl">
        <DialogTitle>Scarica il file</DialogTitle>
        <FrontDownloadModel :model-value="content" />
      </DialogScrollContent>
    </Dialog>
  </template>
</template>
