<script setup lang="ts">
import { type Norma } from "@gasparigit/omnia-sdk";
import { link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

const props = defineProps<{
  content: Norma;
  compact?: boolean;
  showAreas?: boolean;
  showChildren?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const articoli = computed(() => {
  if (!props.content?.articoli?.length) return null;

  const seen = new Map<string, boolean>();

  return props.content.articoli.map((articolo) => {
    if (articolo.comma) {
      return {
        ...articolo,
        abstract: null,
      };
    }

    return articolo;

    // const key = `${articolo.abstract || ""}_${articolo.number || ""}`;

    // if (!seen.has(key)) {
    //   seen.set(key, true);
    //   return articolo;
    // }

    // return {
    //   ...articolo,
    //   abstract: null,
    // };
  });
});
</script>

<template>
  <FrontContentItemsBaseContent v-bind="$props">
    <template #info v-if="content.publisher">
      <span class="chip chip-solid-gray inline-block mt-2">{{ content.publisher?.title }}</span>
    </template>
    <template #footer v-if="showChildren && articoli">
      <div class="mt-3" v-for="articolo in articoli">
        <h4>
          <NuxtLink :href="link({ ...linkConfig, content: articolo })" target="_blank" rel="noopener noreferrer" class="link font-semibold">{{ articolo.title }}</NuxtLink>
        </h4>
        <p class="text-themeGray text-base leading-normal" v-if="articolo.abstract">{{ articolo.abstract }}</p>
      </div>
    </template>
  </FrontContentItemsBaseContent>
</template>
