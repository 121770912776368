<script setup lang="ts">
import type { Modello } from "@gasparigit/omnia-sdk";

const content = defineModel<Modello>({ required: true });
const { data: loggedUser } = useLoggedUser(null, false);

const autocompleteCustomerId = ref<string>("-1");
const autocompleteCustomer = computed(() => {
  if (autocompleteCustomerId.value === "-1") return undefined;
  return loggedUser.value?.customers?.find((customer) => customer.id.toString() === autocompleteCustomerId.value);
});
</script>

<template>
  <h6 class="text-xl font-bold mb-3">Formati disponibili</h6>
  <div v-if="content.files?.length && !content.is_demo">
    <Accordion type="single" default-value="0">
      <template v-for="(attachment, i) in content.files">
        <AccordionItem v-if="attachment.file" :value="i.toString()" class="bg-primary/5 rounded-sm border border-primary/15 mt-2">
          <AccordionTrigger class="px-5 font-medium">{{ attachment.file.extension }}</AccordionTrigger>
          <AccordionContent class="px-5 pt-5 bg-background">
            <div v-if="attachment.is_autofillable && loggedUser && loggedUser?.customers?.length" class="grid gap-3 mb-5">
              <Label for="autofill_customer_id">Autocompila con i seguenti dati</Label>
              <Select name="autofill_customer_id" v-model="autocompleteCustomerId">
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="-1">Non autocompilare</SelectItem>
                  <SelectItem v-for="customer in loggedUser?.customers" :key="`customer-${customer.id}`" :value="customer.id.toString()">
                    {{ customer.name }}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <FrontDownloadButton :title="`${attachment.title} - ${content.title}`" :content="content" :file="attachment" :customer="autocompleteCustomer" class="flex items-center text-left">
              <IconsAttach class="w-7 h-7 me-2" />
              <div class="grid gap-1 justify-start">
                <div class="link font-semibold text-lg">
                  <span class="me-2">{{ attachment.title }}</span>
                  <span class="uppercase whitespace-nowrap">({{ attachment.file.human_readable_size }})</span>
                </div>
                <template v-if="attachment.is_autofillable">
                  <span class="text-muted-foreground" v-if="autocompleteCustomer">
                    Autocompilato con i dati di: <strong>{{ autocompleteCustomer?.name }}</strong>
                  </span>
                  <span class="text-muted-foreground" v-else>Non autocompilato</span>
                </template>
              </div>
            </FrontDownloadButton>
          </AccordionContent>
        </AccordionItem>
      </template>
    </Accordion>
  </div>
  <div v-else>
    <span class="text-muted-foreground">Nessun formato disponibile</span>
  </div>
</template>
