<script setup lang="ts">
import { type Quesito } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: Quesito;
  compact?: boolean;
  showAreas?: boolean;
  showChildren?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", { dateStyle: "long" });
</script>

<template>
  <FrontContentItemsBaseContent v-bind="$props">
    <template #preview>
      <IconsHelp class="flex-none text-primary w-12 h-12 mb-2"></IconsHelp>
    </template>
    <template #info v-if="content.date">
      <p class="info-number mt-2 md:mt-0">Quesito del {{ df.format(content.date) }}</p>
    </template>
  </FrontContentItemsBaseContent>
</template>
