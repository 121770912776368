<script setup lang="ts">
import { LINK_TYPE_ENUM, type Link } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";

defineProps<{
  content: Link;
  compact?: boolean;
  showAreas?: boolean;
}>();

const df = new DateFormatter("it-IT", { dateStyle: "long" });
</script>

<template>
  <FrontContentItemsBaseContent v-bind="$props" :info-label="LINK_TYPE_ENUM[content.link_type]">
    <template #info>
      <p v-if="content.link_type === 'gazzetta' && content.date && content.number" class="info-number mt-2 md:mt-0">Gazzetta n. {{ content.number }} del {{ df.format(content.date) }}</p>
      <p v-else-if="content.link_type === 'portali' && content.date" class="info-number mt-2 md:mt-0">Pubblicazione del {{ df.format(content.date) }}</p>
    </template>
    <template #preview>
      <div v-if="content.link_type === 'gazzetta' && content.number" class="flex-none flex flex-col items-start justify-center text-base mb-2 md:mb-0">
        <span class="text-themeGray font-semibold">G.U. n.</span>
        <div class="min-w-14 h-14 p-2 flex items-center justify-center font-monospace font-bold bg-primary rounded-full text-white mt-1">{{ content.number }}</div>
      </div>
      <img
        v-else-if="(content.link_type === 'ebook' || content.link_type === 'corso-formazione') && content.image?.original_url"
        :src="content.image?.original_url"
        :srcset="content.image?.srcset"
        class="mx-auto object-cover max-h-32 mb-2 md:mb-0"
      />
      <IconsRepubblica v-else-if="content.link_type === 'gazzetta'" class="flex-none text-primary w-12 h-12 mb-2"></IconsRepubblica>
      <IconsWebsite v-else-if="content.link_type === 'portali'" class="flex-none text-primary w-12 h-12 mb-2"></IconsWebsite>
    </template>
    <template #header v-if="content.publisher">
      <div class="mb-2">
        <span class="chip chip-solid-gray inline-block">{{ content.publisher?.title }}</span>
      </div>
    </template>
  </FrontContentItemsBaseContent>
</template>
