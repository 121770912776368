<script setup lang="ts">
import { type Area } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { AREAS_MAP } from "~/lib/const";
import { cn } from "~/lib/utils";

defineProps<{
  title: string;
  abstract?: string | null;
  areas?: Area[] | null;
  compact?: boolean;
  showAreas?: boolean;
  infoLabel?: string;
  date?: Date;
  link?: string;
  highlight?: boolean;
}>();

const df = new DateFormatter("it-IT", {
  dateStyle: "long",
});
</script>

<template>
  <div :class="cn('relative sm:flex h-full gap-5', highlight ? 'p-5 rounded-lg border-2 border-primary-200' : 'border-b last:border-none last:pb-0 pb-6')">
    <div v-if="highlight" class="triangle absolute top-[-2px] right-[-2px] rounded-tr-lg w-[25px] h-[25px] bg-primary-500"></div>

    <slot name="preview" v-if="!compact"></slot>

    <div class="grow flex flex-col">
      <div class="md:flex justify-between gap-1 mb-2 md:items-start" :class="compact && 'flex-col'">
        <div class="info-label">{{ infoLabel }}</div>
        <slot name="info"></slot>
        <div v-if="!$slots.info && date">
          <p class="info-number mt-2 md:mt-0">{{ df.format(date) }}</p>
        </div>
      </div>

      <slot name="header"></slot>

      <div :class="compact ? 'flex flex-col gap-y-3' : 'flex flex-col md:flex-row justify-between gap-3'">
        <h3 class="font-semibold text-xl">
          <NuxtLink
            :target="link.startsWith('http') && !link.startsWith(useRequestURL().origin) ? '_blank' : undefined"
            v-if="link"
            :to="link"
            :title="title"
            class="hover:text-primary-600 transition-colors"
          >
            {{ title }}
          </NuxtLink>
          <span v-else>{{ title }}</span>
        </h3>
        <slot name="right"></slot>
      </div>

      <p v-if="!compact" class="text-themeGray mt-2 line-clamp-6">
        {{ abstract }}
      </p>

      <slot name="center"></slot>

      <div v-if="!compact && showAreas" class="flex flex-wrap gap-x-2 font-semibold text-base pt-2 mt-auto">
        <span v-for="area in areas" :class="['text-' + AREAS_MAP[area.id].color + '-foreground']">{{ area.title }}</span>
      </div>

      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style>
.triangle {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
</style>
